import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import * as queryString from "query-string";

const SubscribedSuccess = ({ location }) => {
  const { name } = queryString.parse(location.search);

  return (
    <div className="d-flex w-100 flex-column align-items-center m-auto">
      <FormattedMessage
        id="flop.mailing.subscription-success"
        values={{ name: name }}
      />
    </div>
  );
};

export default SubscribedSuccess;
